/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { destroyCookie, parseCookies, setCookie } from "nookies";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { LocationLockStaticConstants, COUNTRIES, GA4_EVENTS, COUNTRIES_CODE } from "../../../../common/constants";
import { requestPlpListingOnClient, requestProductsOnPlp } from "../../../../../src/actions/plp-actions";
import {
  setShowLocationPopUp,
  setFinalSelectedPinCode,
  setFinalSelectedCountry,
  setSelectedDeliveryDate,
  setItemDesiredDeliveryDate,
  setShowLocationTooltip,
  setAdobeAutoManualPopup,
} from "../../../../../src/actions/location-lock-actions";
import { pincodeGaEvent } from "../../../../../src/ga/gaEvents";
import { cdpGetLocation } from "../../../../../src/helpers/CDPHelper";
import {
  RoutePageConstants,
  getRoutePageName,
  dateToDMY,
  isJsonString,
  FnpPageType,
} from "../../../../../src/utils/common";
import isIndPincode from "../../../../../src/utils/pincodeUtil";
import TooltipCustom from "../../../../desktop/pdp/tooltip";
import { setOnClickData } from "../../../../../src/omniture/omniture-utility";
import { GATE_POPUP_TRACKING } from "../../../../../src/omniture/omniture-consts";

const LocationPopup = dynamic(() => import("./location-popup"), { ssr: false });

const useStyles = makeStyles((theme) => ({
  locationLockBtn: {
    display: "flex",
    justifyContent: "space-between",
    height: "40px",
    color: "#555",
    fontSize: "12px",
    fontWeight: "600 !important",
    borderRadius: "4px",
    backgroundColor: theme.color?.white,
    margin: "0 16px 0",
    padding: "5px",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 2px 3px rgba(0, 0, 0, 0.3)",
    },
    lineHeight: 5,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  iconCont: {
    color: theme.color?.lightGrey,
    marginRight: "3px",
  },
  pinCodeValue: {
    color: theme.color?.lightGrey,
    fontSize: "12px",
    fontWeight: 500,
  },
  selectedPin: {
    textDecoration: "underline",
  },
  textContainer: { display: "flex", alignItems: "center" },
  text: {
    textOverflow: "ellipsis",
    maxWidth: 170,
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "block",
    marginLeft: 5,
  },
  deliveryText: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" },
  tooltipWrapper: {
    position: "relative",
    marginTop: "60px",
    marginLeft: "-200px",
  },
}));

/**
 * This function return the Location lock component
 *
 * @returns {React.ReactElement} jsx for the Location lock.
 */
function LocationLock() {
  const [isButtonDisable, setButtonDisable] = useState(true);
  const [statusIndia, setStatusIndia] = useState(LocationLockStaticConstants.WITHIN_INDIA);
  const {
    finalSelectedPinCode,
    selectedPinCode,
    showLocationLockModal,
    finalSelectedCountry,
    adobeAutoManualPopup,
    selectedCountry: getSelectedCountry,
    selectedCity,
    autoGatePopupConfig,
  } = useSelector((store) => store.locationLockLists);
  const {
    autoTriggerLock: autoTriggerLockTime,
    isAutoGateEnabledOnDesktopHome,
    isAutoGateEnabledOnDesktopPLP,
  } = autoGatePopupConfig;

  const params = useSelector((store) => store.productsLists.searchOptions);
  const { showLocationToolTip } = useSelector((store) => store.locationLockLists);
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    lastSelectedPincode,
    lastSelectedAddress,
    selectedCountries,
    autoTriggerLock,
    lastSelectedDeliveryDate,
    faul,
  } = parseCookies();

  const { isCdpEnabled } = useSelector((state) => state.appConfigs.configData.desktop);
  const catalogId = useSelector((store) => store.appConfigs?.catalogId);
  const router = useRouter();
  const isDateLockEnabled = useSelector((store) => store.appData.isDateLockEnabled);
  const checkLoginInfo = useSelector((state) => state?.userDetails?.checkLoginInfo);
  const isUserLoggedIn = checkLoginInfo?.loggedin;
  const isHomePage = getRoutePageName(router?.pathname) === RoutePageConstants.HOME;
  const isPlpPage = getRoutePageName(router?.pathname) === RoutePageConstants.PLP_PAGE;
  const selectedCountryName = getSelectedCountry?.name || "";
  const selectedCityName = selectedCity?.cityName || "";
  const shouldTriggerLock = Boolean(
    !lastSelectedPincode && !showLocationLockModal && !autoTriggerLock && autoTriggerLockTime,
  );
  const getSelectedPincode = selectedPinCode || "";
  const pageData = {
    channel: isHomePage ? FnpPageType.HOME : FnpPageType.PLP,
    pageCategories: isHomePage ? FnpPageType.HOME : FnpPageType.PLP,
  };
  useEffect(() => {
    if (shouldTriggerLock) {
      if (isAutoGateEnabledOnDesktopHome && isHomePage) {
        window.localStorage.setItem("auto-gate-open-home", true);
        dispatch(setAdobeAutoManualPopup("auto"));
        dispatch(setShowLocationPopUp(true));
      } else if (isAutoGateEnabledOnDesktopPLP && isPlpPage) {
        window.localStorage.setItem("auto-gate-open-plp", true);
        dispatch(setAdobeAutoManualPopup("auto"));
        dispatch(setShowLocationPopUp(true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoGateEnabledOnDesktopHome, isAutoGateEnabledOnDesktopPLP]);

  /**
   * To handle click away
   */
  const handleClickAway = () => {
    if (showLocationToolTip) {
      dispatch(setShowLocationTooltip(false));
    }
  };

  useEffect(() => {
    if (lastSelectedPincode) {
      dispatch(setFinalSelectedPinCode(lastSelectedPincode));
      pincodeGaEvent(GA4_EVENTS.PINCODE_AVAILABLE, lastSelectedPincode);
      if (lastSelectedDeliveryDate) {
        dispatch(setSelectedDeliveryDate(lastSelectedDeliveryDate));
        dispatch(setItemDesiredDeliveryDate(dateToDMY(new Date(lastSelectedDeliveryDate))));
      }
      if (isCdpEnabled) {
        const pageType = getRoutePageName(router.asPath.startsWith("/search") ? "/search" : router.pathname);
        if (pageType !== RoutePageConstants.PDP_PAGE) {
          cdpGetLocation(lastSelectedPincode, null, null, lastSelectedAddress);
        }
      }
    } else {
      dispatch(setFinalSelectedPinCode(null));
      dispatch(setSelectedDeliveryDate(""));
      dispatch(setItemDesiredDeliveryDate(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isCdpEnabled, lastSelectedPincode, router, lastSelectedDeliveryDate]);

  useEffect(() => {
    if (selectedCountries && isJsonString(selectedCountries)) {
      const selectedCountriesValues = Object.values(JSON.parse(selectedCountries));
      const selectedCountry = selectedCountriesValues.filter((country) => {
        return country.catalogId === catalogId;
      });
      if (selectedCountry.length > 0) {
        dispatch(
          setFinalSelectedCountry({
            ...selectedCountry[0],
          }),
        );
      }
    }
  }, [selectedCountries, dispatch, catalogId]);
  /**
   * This function handles the button disability
   *
   * @param {boolean} flag boolean to handle the button disable
   * @returns {object} to set button props
   */
  const handleButtonDisable = (flag) => setButtonDisable(flag);

  /**
   * This function set status according to the catalog
   */
  const setStatusAccordingToCatalog = useCallback(() => {
    if (catalogId !== COUNTRIES.INDIA) {
      setStatusIndia(LocationLockStaticConstants.OUTSIDE_INDIA);
    } else {
      setStatusIndia(LocationLockStaticConstants.WITHIN_INDIA);
    }
    handleButtonDisable(true);
  }, [catalogId]);

  /**
   * THis function handles the modal view of location lock
   *
   */
  const handleLocationLock = () => {
    dispatch(setAdobeAutoManualPopup("manual"));
    if (isHomePage) {
      window.localStorage.removeItem("auto-gate-open-home");
    } else if (isPlpPage) {
      window.localStorage.removeItem("auto-gate-open-plp");
    }
    const getPincode = selectedPinCode || lastSelectedPincode || "";
    const gateData = {
      link: {
        linkName: LocationLockStaticConstants.DELIVER_TO,
      },
      category: {
        country: statusIndia,
        countryname: statusIndia === LocationLockStaticConstants.WITHIN_INDIA ? COUNTRIES.INDIA : selectedCountryName,
        city: statusIndia === LocationLockStaticConstants.WITHIN_INDIA ? "" : selectedCityName,
        pincode: statusIndia === LocationLockStaticConstants.WITHIN_INDIA ? getPincode : "",
      },
    };
    const clickData = {
      category: { ...gateData.category },
      link: { ...gateData.link },
    };
    setOnClickData(clickData, checkLoginInfo, pageData);
    dispatch(setShowLocationPopUp(true));
  };

  /**
   *  This function contains adobe data for gate tracking
   *
   * @param {boolean} bool flag for determining which icon is clicked for  closing the modal
   * @param {string} outside flag for determining which icon is clicked for  closing the modal
   */
  function setGateAdobeData(bool, outside) {
    const gateData = {
      link: {},
      category: {
        country: statusIndia,
        countryname: statusIndia === LocationLockStaticConstants.WITHIN_INDIA ? COUNTRIES.INDIA : selectedCountryName,
        city: statusIndia === LocationLockStaticConstants.WITHIN_INDIA ? "" : selectedCityName,
        pincode: statusIndia === LocationLockStaticConstants.WITHIN_INDIA ? getSelectedPincode : "",
      },
    };
    if (isHomePage) {
      window.localStorage.removeItem("auto-gate-open-home");
    } else if (isPlpPage) {
      window.localStorage.removeItem("auto-gate-open-plp");
    }
    if (outside === LocationLockStaticConstants.OUTSIDE) {
      if (adobeAutoManualPopup === GATE_POPUP_TRACKING.AUTO) {
        gateData.link.linkName = GATE_POPUP_TRACKING.AUTO_GREY_AREA_CLICKED;
      } else if (adobeAutoManualPopup === GATE_POPUP_TRACKING.MANUAL) {
        gateData.link.linkName = GATE_POPUP_TRACKING.MANUAL_GREY_AREA_CLICKED;
      }
      const clickData = {
        category: { ...gateData.category },
        link: { ...gateData.link },
      };
      setOnClickData(clickData, checkLoginInfo, pageData);
    } else if (bool === LocationLockStaticConstants.ICON) {
      if (adobeAutoManualPopup === GATE_POPUP_TRACKING.AUTO) {
        gateData.link.linkName = GATE_POPUP_TRACKING.AUTO_GATE_CLOSE;
      } else if (adobeAutoManualPopup === GATE_POPUP_TRACKING.MANUAL) {
        gateData.link.linkName = GATE_POPUP_TRACKING.MANUAL_GATE_CLOSE;
      }
      const clickData = {
        category: { ...gateData.category },
        link: { ...gateData.link },
      };
      setOnClickData(clickData, checkLoginInfo, pageData);
    }
  }

  /**
   * This function handles the click functionality of the close button
   *
   * @param {boolean} bool flag for determining which icon is clicked for  closing the modal
   * @param {string} outside flag for determining which icon is clicked for  closing the modal
   */
  const handleLocationLockClose = (bool, outside) => {
    setGateAdobeData(bool, outside);
    setStatusAccordingToCatalog();
    dispatch(setShowLocationPopUp(false));

    if (isHomePage || isPlpPage) {
      setCookie({}, LocationLockStaticConstants.AUTO_TRIGGER_LOCK, autoTriggerLockTime, {
        maxAge: autoTriggerLockTime,
        path: "/",
        encode: decodeURIComponent,
        secure: true,
      });
    } else {
      destroyCookie({}, LocationLockStaticConstants.AUTO_TRIGGER_LOCK, {
        path: "/",
      });
    }

    if (bool === LocationLockStaticConstants.ICON) {
      const options = {
        path: "/",
      };
      if (!selectedPinCode && lastSelectedPincode) {
        destroyCookie(null, LocationLockStaticConstants.PF, options);
        destroyCookie(null, LocationLockStaticConstants.LAST_SELECTED_PINCODE, options);
        destroyCookie(null, LocationLockStaticConstants.LAST_SELECTED_ADDRESS, options);
        destroyCookie(null, LocationLockStaticConstants.LAST_SELECTED_DELIVERY_DATE, options);
        dispatch(setFinalSelectedPinCode(null));
        if (isUserLoggedIn) {
          dispatch({
            type: LocationLockStaticConstants.SET_LOGGED_IN_PINCODE,
            payload: { pincode: " ", email: faul, customerAddress: " " },
          });
        }
        if (window.checkPincodeSelectedOnLoad) {
          window.checkPincodeSelectedOnLoad();
        }

        if (Object.keys(params).length !== 0) {
          const newParams = { ...params };
          delete newParams.params.pincode;
          delete newParams.params.deliveryDate;
          newParams.params.page = 0;
          dispatch(requestProductsOnPlp());
          dispatch(requestPlpListingOnClient({ options: newParams, appendProducts: false }));
        }
      }
    }
  };

  /**
   * This function handles the radio button check
   *
   * @param {object} event object of target event
   */
  const handleRadioChange = (event) => {
    setStatusIndia(event.target.value);
    if (event.target.value === LocationLockStaticConstants.WITHIN_INDIA && lastSelectedPincode && selectedPinCode) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  };

  useEffect(() => {
    setStatusAccordingToCatalog();
  }, [setStatusAccordingToCatalog]);

  /**
   * Function returns deliver to text content
   *
   * @returns {React.ReactNode} deliver to text content
   */
  const getDeliverToText = () => {
    if (catalogId === COUNTRIES.INDIA && isIndPincode(finalSelectedPinCode)) {
      return (
        <span>
          {LocationLockStaticConstants.DELIVER_TO}
          &nbsp;
          <u>{finalSelectedPinCode}</u>
        </span>
      );
    }
    if (finalSelectedCountry && Object.keys(finalSelectedCountry).length > 0) {
      if (finalSelectedCountry?.country && finalSelectedCountry?.city) {
        return (
          <span className={classes.textContainer}>
            {`${finalSelectedCountry?.city}, ${finalSelectedCountry?.country}`.length <= 15
              ? `${LocationLockStaticConstants.DELIVER_TO}  `
              : null}
            <u className={classes.text} title={`${finalSelectedCountry?.city}, ${finalSelectedCountry?.country}`}>
              {`${finalSelectedCountry?.city}, ${finalSelectedCountry?.country}`}
            </u>
          </span>
        );
      }
      return <span className={classes.pinCodeValue}>{LocationLockStaticConstants.SELECT_DELIVERY_CITY}</span>;
    }
    if (catalogId !== COUNTRIES.INDIA) {
      return <span className={classes.pinCodeValue}>{LocationLockStaticConstants.SELECT_DELIVERY_CITY}</span>;
    }
    return (
      <span className={classes.pinCodeValue}>
        {isDateLockEnabled
          ? LocationLockStaticConstants.SELECT_DELIVERY_LOCATION_DATE
          : LocationLockStaticConstants.SELECT_DELIVERY_LOCATION}
      </span>
    );
  };

  return (
    <>
      <Grid>
        <Grid className={classes.locationLockBtn} id="location-lock" onClick={handleLocationLock}>
          <LocationOnIcon style={{ fontSize: 16 }} className={classes.iconCont} />
          {getDeliverToText()}
          <KeyboardArrowRightIcon style={{ fontSize: 16 }} className={classes.iconCont} />
        </Grid>
        {showLocationLockModal && (
          <LocationPopup
            isButtonDisable={isButtonDisable}
            openLocationLockModal={showLocationLockModal}
            handleLocationLockClose={handleLocationLockClose}
            handleRadioChange={handleRadioChange}
            handleButtonDisable={handleButtonDisable}
            statusIndia={statusIndia}
          />
        )}
      </Grid>
      {showLocationToolTip && (
        <div className={classes.tooltipWrapper}>
          <TooltipCustom
            handleClickAway={handleClickAway}
            color="black"
            position="top"
            value="Please enter delivery pincode"
          />
        </div>
      )}
    </>
  );
}
export default LocationLock;
